<template>
  <div class="client-myTab1">
    <div class="client-main" v-if="row">
      <els-form
        ref="tabForm"
        :column="tabsColumn"
        v-model="modelData" :ctx="{lang: $t('d')}"
      ></els-form>
      <div class="client-footer">
        <el-button type="primary" v-if="isShow" @click="editok">{{$t('d.edit')}}</el-button>
        <div class="tab-footer" v-if="!isShow">
          <el-button @click="cancelData">{{$t('d.cancel')}}</el-button>
          <el-button type="primary" @click="changeEdit" :loading="isSure"
            >{{$t('d.save')}}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import elsForm from "../../../components/els-form/main.vue";
import { mapState } from "vuex";
import { treeMap } from "../../../utils/tree.js";
import { addDeviceType } from "@/api/manager/client-type-manage.js";
import { getDict } from "@/api/com/iot-dict.js";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  name: "client-tab1",
  mixins: [mixinsI18n],
  components: { elsForm },
  props: {
    tabColumn: Array,
  },
  data: () => {
    return {
      isSure: false,
      isShow: true,
      modelData: {},
      disablelist: [
        "deviceTypeName",
        "typeClass",
        "networkingMode",
        "protocol",
        "accessType",
        "dataType",
        "selfTag",
        "describe",
      ],
      dlist: ["key", "value"],
      saveRow: {},
      PAGE_NAME: "clientTypePage",
    };
  },
  computed: {
    ...mapState("clientType", {
      row(state) {
        this.handleOn(state.row);
        return state.row;
      },
    }),
    tabsColumn() {
      const t = this.disablelist;
      const col = treeMap(
        this.tabColumn,
        (n) => {
          if (n.prop) {
            n.disabled = t.includes(n.prop);
          }
          if (n.prop && n.prop === "selfTag") {
            n.column.forEach((o) => {
              if (o.prop) {
                o.form.disabled = this.dlist.includes(o.prop);
              }
            });
          }
        },
        { children: "cls" }
      );
      return col;
    },
  },
  methods: {
    editok() {
      this.isShow = !this.isShow;
      this.disablelist =
        this.isShow === true
          ? [
              "deviceTypeName",
              "typeClass",
              "networkingMode",
              "protocol",
              "accessType",
              "dataType",
              "selfTag",
              "describe",
            ]
          : ["accessType", "protocol", "dataType"];
      this.dlist = this.isShow === true ? ["key", "value"] : [];
      // 保存
    },
    async changeEdit() {
      this.isSure = true;
      const app = this.$refs.tabForm;
      let tabform = this.$refs.tabForm.formData;
      try {
        app && (await app.validate());
        const d = {
          id: tabform.sourceId,
          taglib: tabform.selfTag ? JSON.stringify(tabform.selfTag) : [],
          deviceTypeName: tabform.deviceTypeName,
          typeClass: tabform.typeClass,
          networkingMode: tabform.networkingMode,
          protocol: tabform.protocol,
          accessType: tabform.accessType,
          dataType: tabform.dataType,
          describe: tabform.describe,
        };
        addDeviceType({ ...d })
          .then((res) => {
            if (res) {
              this.isSure = false;
              this.editok();
              this.saveRow = res;
              this.$message.success(this.lang.saveSuccess);
            }
          })
          .catch((err) => {
            this.isSure = false;
          });
      } catch (err) {
        this.isSure = false;
        console.log(err);
      }
    },
    handleOn(d) {
      const p = { ...d };
      p.selfTag = p.taglib ? JSON.parse(p.taglib) : [];
      this.modelData = p ? JSON.parse(JSON.stringify(p)) : {};
    },
    // 取消
    cancelData() {
      this.editok();
      this.modelData = !!this.saveRow.name
        ? JSON.parse(JSON.stringify(this.saveRow))
        : JSON.parse(JSON.stringify(this.row));
      this.$refs.tabForm.clearValidate();
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
::v-deep .el-select {
  width: 100%;
}
.client-myTab1 {
  .client-main {
    width: 100%;
    .label-name {
      margin-bottom: 10px;
    }
    .client-footer {
      padding-bottom: 10px;
      text-align: right;
    }
  }
}
</style>
